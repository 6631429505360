<template>
  <moe-page title="新建活动">
    <moe-form
      class="default-form"
      ref="goodsBadgeForm"
      :showBack="false"
      :showClose="true"
      :model="goodsBadgeParams"
      :rules="rules">
      <div style="width: 50%;" class="margin-0-auto">
        <div class="fwb mb-20 font-20">基本信息</div>

        <el-form-item label="活动名称" prop="name" label-width="150px">
          <el-input class="w-300" v-model.trim="goodsBadgeParams.name" placeholder="请输入活动名称，2到20个文字组合" clearable maxlength="20"></el-input>
          <div class="color-info font-10 lh1 mt-10">活动名称仅用于商家自己管理活动，不会显示给用户</div>
        </el-form-item>

        <el-form-item label="活动时间" prop="startTime" label-width="150px">
          <el-date-picker
            class="w-500"
            style="width: 100%;"
            placement="bottom-start"
            v-model="datetime"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            @change="(datetime) => ([goodsBadgeParams.startTime, goodsBadgeParams.endTime] = datetime || ['', ''])" />
        </el-form-item>

        <el-form-item label="活动图标" prop="cover" label-width="150px">
          <div class="color-info font-10 lh1 mt-10 mb-20">活动图标将覆盖到商品主图上面，请注意最终展示效果</div>
          <moe-upload-file
            v-model="goodsBadgeParams.cover"
            ossKey="SHOP_MEDIA"
            :default-file-list="coverList"
            type="image"
            upload-text="上传图片"
            @change="$refs.paramsForm.validateField('cover')" />
        </el-form-item>

        <el-form-item label="活动图标位置" prop="style" label-width="150px">
          <moe-select class="w-300" type="goodsBadgeStyleList" v-model="goodsBadgeParams.style" placeholder="请选择活动图标位置"></moe-select>
        </el-form-item>
      </div>

      <div class="fwb mb-20 font-20">活动商品</div>

      <div style="height: 700px;min-height: 700px;" class="df fdc">
        <moe-table
          :stripe="true"
          :numberShow="false"
          :data="goodsBadgeParams.configList"
          :mode="false"
          :showPage="false"
          :params="{ pageNum: 1 }"
          emptyText="请添加活动商品"
          rowKey="goodsId">
          <el-form-item prop="configList" slot="tool">
            <div class="df aic">
              <el-button type="primary" icon="el-icon-circle-plus-outline" @click="showDialog = true;">添加商品</el-button>
              <div class="df aic ml-10">已选<div class="color-primary">{{ `${goodsBadgeParams.configList.length}个` }}</div>商品</div>
            </div>
          </el-form-item>

          <el-table-column label="商品信息" min-width="200">
            <template slot-scope="{ row }">
              <div class="df aic jcc">
                <moe-image :src="row.coverUrl" width="80px" height="80px" />
                <div class="df1 tal ml-10">
                  <p class="fwb">{{ row.name }}</p>
                  <p class="font-12 color-info">商品编号: {{ row.goodsId }}</p>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="销售价" prop="salePrice" min-width="150" :formatter="salePriceComputed" />
          <el-table-column label="库存" prop="stock" width="80" />
          <el-table-column label="操作" width="200" fixed="right">
            <template slot-scope="{ row, $index }">
              <el-button icon="el-icon-delete" size="small" type="danger" @click="handleDelete(row, $index)">删除</el-button>
            </template>
          </el-table-column>
        </moe-table>
      </div>

      <el-button slot="tool" type="primary" icon="el-icon-upload2" :loading="goodsBadgeLoad" @click="handleSubmit()">{{ goodsBadgeLoad ? '保存中' : '提交' }}</el-button>
    </moe-form>

    <goodsDialog
      :showActivityRepel="true"
      type="GOODS_BADGE"
      :showDialog.sync="showDialog"
      :defaultSelectIds="goodsBadgeParams.configList && goodsBadgeParams.configList.length ? goodsBadgeParams.configList.map(({ id }) => id) : []"
      @close="handleClose">
    </goodsDialog>
  </moe-page>
</template>

<script>
import goodsDialog from '@/views/ActivityManage/components/goodsDialog.vue';
export default {
  name: 'ActivityManageGoodsBadgeAdd',
  components: {
    goodsDialog
  },
  computed: {
    IS_EDIT() {
      return this.goodsBadgeParams.id ? true : false
    }
  },
  data() {
    const verify = this.$moe_verify.verifyForm;
    return {
      goodsBadgeParams: {
        id: '',
        name: '',
        startTime: '',
        endTime: '',
        cover: '',
        style: '',
        configList: [],
      },
      datetime: [],
      coverList: [],
      rules: {
        name: verify.isExtent(['请输入活动名称', '长度为 2 ~ 20位'], '2~20'),
        startTime: verify.isEmpty('请选择活动时间'),
        cover: verify.isEmpty('请上传活动图标'),
        style: verify.isEmpty('请选择活动图标位置'),
        configList: verify.isEmpty('请添加活动商品'),
      },
      goodsBadgeLoad: false,
      showDialog: false,
    }
  },
  methods: {
    handleSubmit() {
      this.$refs['goodsBadgeForm'].validate(() => {
        this.goodsBadgeLoad = true;
        const { configList, style } = this.goodsBadgeParams
        let params = {
          ...this.goodsBadgeParams,
          configList: configList.map(({ goodsId, id }) => {
            return {
              goodsId,
              id: id || ''
            }
          }),
          style: JSON.stringify(this.$moe_format.getGoodsBadgeStyle(style)),
        }
        if (this.IS_EDIT) {
          this.req_updateBuyGift(params);
        } else {
          this.req_addBuyGift(params);
        }
      })
    },
    salePriceComputed({ minPrice, maxPrice }) {
      return `¥ ${minPrice}${this.$moe_math.mathGreaterThan(maxPrice, minPrice) ? ` - ¥ ${maxPrice}` : ''}`;
    },
    formatterStyle({ top, bottom }) {
      if (top === 0) return 1
      if (bottom === 0) return 2
    },
    handleClose(selectList) {
      selectList.forEach((item) => {
        let findItem = this.goodsBadgeParams.configList.find(({ goodsId }) => item.id === goodsId);
        if (!findItem) {
          this.goodsBadgeParams.configList.push({
            ...item,
            goodsId: item.id,
            id: ''
          });
        }
      })

      this.showDialog = false;
    },
    /** 删除添加的商品 */
    handleDelete({ name }, index) {
      this.$moe_layer.confirm(`您确定要删除当前选项“${name}”吗？`, () => {
        this.goodsBadgeParams.configList.splice(index, 1);
        this.$moe_msg.success('删除成功,提交后更新');
      });
    },
    /** 创建主图打标 */
    async req_addBuyGift(params) {
      const { code, message } = await this.$moe_api.GOODSBADGE_API.addGoodsBadge(params);
      this.goodsBadgeLoad = false;
      if (code === 200) {
        this.$moe_msg.success('新建成功', {
          completionHandler: () => {
            this.$moe_coordinator.navigateBack();
          }
        });
      } else {
        this.$moe_msg.error(message);
      }
    },
    /** 修改主图打标 */
    async req_updateBuyGift(params) {
      const { code, message } = await this.$moe_api.GOODSBADGE_API.updateGoodsBadge(params);
      this.goodsBadgeLoad = false;
      if (code === 200) {
        this.$moe_msg.success('编辑成功', {
          completionHandler: () => {
            this.$moe_coordinator.navigateBack();
          }
        });
      } else {
        this.$moe_msg.error(message);
      }
    },
    /** 获取主图打标详情 */
    async req_getBuyGiftDetail() {
      if (this.$route.query.id) {
        const { code, result, message } = await this.$moe_api.GOODSBADGE_API.getGoodsBadgeDetail({ id: this.$route.query.id });
        if (code === 200) {
          const { id, name, startTime, endTime, cover, style, configList } = result;
          this.goodsBadgeParams = {
            id,
            name,
            startTime,
            endTime,
            cover: this.$moe_yunHelper.formatterImageUrl(cover),
            style: this.formatterStyle(JSON.parse(style)),
            configList: []
          };
          this.datetime = [startTime, endTime];
          this.coverList = [{
            url: cover,
            tempFilePath: this.$moe_yunHelper.getTempFilePath(cover),
          }];

          this.getQueryShopGoodsByIds(configList);
        } else {
          this.$moe_msg.error(message);
        }
      }
    },
    /** 根据商品ID获取商品列表 */
    async getQueryShopGoodsByIds(goodsList) {
      const { code, result, message } = await this.$moe_api.GOODS_API.getQueryShopGoodsByIds({ ids: goodsList.map(({ goodsId }) => goodsId).join(',') });
      if (code === 200) {
        this.goodsBadgeParams.configList = goodsList.map(({ goodsId, id }) => {
          let findGoodsItem = result.find(({ id }) => goodsId === id);
          return {
            ...findGoodsItem,
            goodsId: findGoodsItem.id,
            id,
          }
        })
      } else {
        this.$moe_msg.error(message);
      }
    },
  },
  mounted() {
    this.req_getBuyGiftDetail();
  }
}
</script>

<style lang="scss"></style>